// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "* {\n  box-sizing: border-box;\n}\n\n.lBEXIWN5z0N5VPW0i1w00 {\n  text-align: center;\n  -webkit-tap-highlight-color: rgba(0,0,0,0);\n  -webkit-tap-highlight-color: transparent;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n      -ms-user-select: none;\n          user-select: none;\n}\n\n::-moz-selection {\n  color: none;\n  background: none;\n}\n\n::selection {\n  color: none;\n  background: none;\n}\n\n::-moz-selection {\n  color: none;\n  background: none;\n}\n", "",{"version":3,"sources":["App.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,0CAA0C;EAC1C,wCAAwC;EACxC,yBAAiB;KAAjB,sBAAiB;MAAjB,qBAAiB;UAAjB,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAHA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB","file":"App.css","sourcesContent":["* {\n  box-sizing: border-box;\n}\n\n.App {\n  text-align: center;\n  -webkit-tap-highlight-color: rgba(0,0,0,0);\n  -webkit-tap-highlight-color: transparent;\n  user-select: none;\n}\n\n::selection {\n  color: none;\n  background: none;\n}\n\n::-moz-selection {\n  color: none;\n  background: none;\n}\n"]}]);
// Exports
exports.locals = {
	"App": "lBEXIWN5z0N5VPW0i1w00"
};
module.exports = exports;
